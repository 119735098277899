<template>
  <div>
    <div class="row">
      <retry-notification :show="error" @retry="updateData()" />
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-card :title="$t('trainings.roles.index')">
          <remote-table
            class="table-hover table-striped"
            :columns="tableFields"
            :data="tableData"
            :loading="loading"
            :search="searchOptions"
            :pagination="pagination"
            crud-links="roles"
            controller="DcpiRoles"
            @update-data="updateData"
            @search-data="searchByName"
            @delete-item="tryDelete"
            @download-item="prepareDownload"
          />
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { ca } from "date-fns/locale";

const RemoteTable = () =>
  import(
    /* webpackPrefetch: true */ "@/components/extras/DataTables/RemoteTable"
  );

export default {
  name: "roles-index",
  components: {
    RemoteTable,
  },
  data() {
    return {
      error: false,
      tableData: [],
      pagination: {},
      searchQuery: "",
      loading: false,
      searchOptions: {
        enabled: true,
        trigger: "enter",
        placeholder: this.$t("tables.actions.search"),
        // externalQuery: searchQuery
      },
      serverParams: {
        columnFilters: {},
        sort: {
          field: "", // example: 'name'
          type: "", // 'asc' or 'desc'
        },

        page: 1, // what page I want to show
        perPage: 50, // how many items I'm showing per page
      },
    };
  },
  computed: {
    tableFields() {
      return [
        {
          name: "name",
          title: this.$t("tables.headings.name"),
          callback: this.translateName,
          sortField: "name",
        },
        {
          name: "trainer",
          title: this.$t("tables.headings.can_train"),
          callback: this.trainerLabel,
          sortField: "trainer",
        },
        {
          name: "__slot:actions",
          visible: this.tableData.length > 0,
          dataClass: "text-right",
          width: "20%",
        },
      ];
    },
  },
  created() {
    this.updateData();
  },
  methods: {
    fixData(data) {
      return data;
    },
    translateName(name) {
      return this.$t(name) || name;
    },
    trainerLabel(approval) {
      const a = approval ? "yes" : "no";
      return this.$t("layout." + a);
    },
    apiUrl(params) {
      let route = "dcpi-roles";
      route += "?page=" + params.page || 0;
      route += "&limit=" + params.perPage || 50;
      if (params.sort && params.sort.field !== "") {
        route += "&sort=" + params.sort.field;
        route += "&direction=" + params.sort.type;
      }

      return route;
    },
    async updateData(params) {
      params = params || this.serverParams;
      this.loading = true;
      this.error = false;

      let response = false;
      try {
        response = await this.$http.get(this.apiUrl(params));
      } catch (err) {
        this.showToast(this.$t("notifications.network.error"), {
          icon: "fa-times",
          position: "top-right",
          duration: 2500,
          fullWidth: false,
        });
        this.loading = false;
        this.error = true;
        return;
      }

      this.tableData = this.fixData(response.data.data);
      this.pagination = response.data.pagination;
      this.loading = false;
    },
    async searchByName(name) {
      if (name === "") return this.updateData();

      let response = false;
      try {
        response = await this.$http.get("dcpi-roles?q=" + name);
      } catch (err) {
        // console.log('Error searching group', err)
        this.loading = false;
        return;
      }

      this.tableData = this.fixData(response.data.data);
      this.pagination = response.data.pagination;
      this.loading = false;
    },
    async tryDelete(item) {
      const result = await this.$swal({
        icon: "warning",
        text: this.$t("notifications.confirm.delete"),
        showCancelButton: true,
        confirmButtonText: this.$t("layout.buttons.confirm"),
        cancelButtonText: this.$t("layout.buttons.cancel"),
      });
      if (result.value !== true) return;

      this.loading = true;
      try {
        await this.$http.delete("dcpi-roles/" + item.id);
      } catch (err) {
        // console.log('Error deleting role', err)
        this.loading = false;
        return;
      }
      this.updateData();
    },
    prepareDownload(format) {
      const prepareRoute = "dcpi-roles/download";
      const downloadRoute = "dcpi-roles/download/";

      return this.downloadFile(format, prepareRoute, downloadRoute);
    },
    async downloadFile(filters, prepareRoute, downloadRoute) {
      let fileName = "";
      const prepare = {
        icon: "info",
        title: this.$t("notifications.download.prepare"),
        confirmButtonText: this.$t("notifications.download.button"),
        text: this.$t("notifications.download.wait"),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false;
          try {
            data = await this.$http.post(prepareRoute, filters);
          } catch (e) {
            this.$swal.insertQueueStep(error);
            return;
          }

          fileName = data.data.data;
          this.$swal.insertQueueStep(download);
        },
      };
      const error = {
        icon: "error",
        title: "Error",
        text: this.$t("notifications.download.error"),
      };
      const download = {
        icon: "success",
        title: this.$t("notifications.download.downloading"),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading();
          let response = false;
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: "blob",
            });
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry]);
            return;
          }

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();

          this.$swal.close();
        },
      };
      const retry = {
        icon: "error",
        title: "Error",
        text: this.$t("notifications.download.failed"),
        confirmButtonText: this.$t("notifications.download.retry"),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download);
        },
      };

      this.$swal.queue([prepare]);
    },
  },
};
</script>
